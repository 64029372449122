$headerColor: #1c1c1c;
$activeColor: #fcb53b;
$footerColor: #6c6c6c;

.navigationMenu {
	display: flex;
	align-items: center;
	color: $headerColor;
	font-weight: 400;

	a.link {
		z-index: 10;

		&:hover {
			color: $activeColor;
		}
	}

	a.linkSecond {
		z-index: 10;

		&:hover {
			color: $activeColor;
		}
	}
}

.burgerMenuBLW {
	display: none;
	flex-direction: column;
	cursor: pointer;
}

.burgerMenuBLW span {
	background-color: #fff;
	height: 3px;
	width: 25px;
	margin: 4px 0;
}

@media (max-width: 768px) {
	.blwBurgerMenu ul {
		display: none;
		flex-direction: column;
		width: 100%;
	}

	.blwBurgerMenu ul.active {
		display: flex;
	}

	.burgerMenuBLW {
		display: flex;
	}

	.blwBurgerMenu ul li {
		text-align: center;
		padding: 15px;
	}
}

.link {
	margin-right: 1rem;
	font-weight: 400;
	transition: all 0.3s;
	color: #1c1c1c;
	transition: all 0.2s;

	font-size: 20px;
	font-weight: 400;
	letter-spacing: 1.2px;
}

.linkSecond {
	margin-right: 1rem;
	font-weight: 400;
	transition: all 0.3s;
	color: white;
	transition: all 0.2s;

	font-size: 20px;
	font-weight: 400;
	letter-spacing: 1.2px;

	&:hover {
		color: $activeColor;
	}
}

.isScrool {
	// font-size: 1rem;
	padding: 0 0rem;
	color: #1c1c1c;
}

.isScrool:last-child {
	// font-size: 1rem;
	padding: 0 0 0 0;
}

.burgerMenu {
	display: flex;
	align-items: center;
	color: $headerColor;
	font-weight: 400;

	a.link {
		padding: 0 2rem;
	}

	a.active {
		color: $activeColor;
	}
}

.footerNavigationMenu {
	color: $footerColor;
	font-weight: 500;

	a {
		padding: 0 1.625rem;
		color: $footerColor;
		transition: all ease 0.3s;
	}

	a.active {
		color: inherit;
	}

	a:hover {
		color: #fff;
		font-weight: 700;
		transition: all ease 0.3s;
	}
}

@media (max-width: 996px) {
	.footerNavigationMenu {
		flex-direction: column;
		line-height: 2rem;
	}

	.burgerNavigationMenu {
		flex-direction: column;
		line-height: 40px;
		color: white;
		font-size: 20px;
	}
}

@media (max-width: 1438px) {
	.itemBurgerState {
		width: 200px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		&:hover {
			color: #fcb53b;
		}
	}
}

@media (max-width: 1087px) {
	.itemBurgerState {
		width: 95px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;

		&:hover {
			color: #fcb53b;
		}
	}
}

.modal {
	position: absolute;
	display: flex;
	flex-direction: column;
	top: 3.6rem;
	left: 33rem;
	width: max-content;
	transition: all 0.5s;
}

.isScroolModal {
	transition: all 0.5s;
	backdrop-filter: blur(7px);
	background: rgba(230, 224, 224, 0.74);

	box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
}

.modalClose {
	position: absolute;
	top: 1rem;
	right: 0rem;
	z-index: 10;
	transition: all 0.3s;
	visibility: hidden;
	opacity: 0;
	padding: 0.5rem;
}

.modalActive {
	position: absolute;
	top: 2rem;
	right: 0rem;
	transition: all 0.3s;
	visibility: inherit;
	opacity: 1;
	padding: 0.5rem;
}

.box {
	display: flex;
	flex-direction: column;
	border-radius: 10px;

	background-color: transparent;
	transition: all 0.5s;
	backdrop-filter: blur(7px);
	background: rgba(230, 224, 224, 0.8);

	box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
	padding: 1rem;
}

.activeBox {
	transition: all 0.5s;
	backdrop-filter: blur(7px);
	background: rgba(230, 224, 224, 0.8);

	box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
}

.modalCloseItem {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-size: 15px;
	font-weight: 400;
	letter-spacing: 1.2px;
	transition: all 0.3s;
	color: white;
	z-index: 11;

	&:hover {
		color: #fcb53b;
	}
}

.modalCloseItemisScrool {
	color: black;

	&:hover {
		color: #fcb53b;
	}
}

.boxDropDown {
	display: flex;
	flex-direction: column;
	position: relative;
	width: max-content;
	z-index: 10;
}

.boxDropDownactive {
	display: flex;
	flex-direction: column;
	position: relative;
	width: max-content;
}

.boxDropDownScrool {
	display: flex;
	flex-direction: column;
	position: relative;
	width: max-content;
}

.noActive {
	visibility: hidden;
	opacity: 0;
	display: flex;
	flex-direction: column;
	transition: all 0.3s;
	position: absolute;
	width: 107px;

	top: 1.5rem;
	left: 0rem;

	padding: 1rem 0.6rem 1rem 0.6rem;
}

.activeDark {
	visibility: inherit;
	opacity: 1;
	display: flex;
	flex-direction: column;
	position: absolute;
	transition: all 0.1s;
	top: 0;
	left: 0rem;
	width: 107px;

	padding: 1rem 0.6rem 1rem 0.6rem;
	padding-top: 3rem;
	backdrop-filter: blur(7px);
	background: rgba(32, 32, 32, 0.74);
	z-index: 10;
}

.activeScrool {
	display: none;
}

.itemHref {
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 1.2px;
	transition: all 0.3s;
	padding: 0.5rem;
	margin-right: 0.5rem;
	color: white;
	cursor: pointer;

	&:hover {
		color: #fcb53b;
	}
}

.itemHrefScrool {
	color: black;

	&:hover {
		color: #fcb53b;
	}
}

.itemHrefSecond {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-size: 20px;
	font-weight: 400;
	letter-spacing: 1.2px;
	transition: all 0.3s;
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	cursor: pointer;
	color: black;

	&:hover {
		color: #fcb53b;
	}
}

.aboutLink {
	z-index: 2001;

	&:hover {
		color: #fcb53b;
		transition: 0.3s all;
	}

	&>a:hover {
		color: #fcb53b;
		transition: 0.3s all;
	}
}

.aboutLinkSecond {
	z-index: 2001;
	color: #1c1c1c;

	&:hover {
		color: #fcb53b;
		transition: 0.3s all;
	}

	&>a:hover {
		color: #fcb53b;
		transition: 0.3s all;
	}
}

.headerBox {
	position: fixed;
	top: 0;
	left: 50%;
	transform: translate(-50%, 0);

	z-index: 130;

	width: 100%;
	max-width: 1920px;
	margin: 0 auto;

	background-color: transparent;
	transition: all 0.5s;
	height: 0rem;
	transition: all 0.3s;
}

.headerBoxActive {
	height: 5rem;
	backdrop-filter: blur(7px);
	background: rgba(230, 224, 224, 0.8);
	transition: all 0.3s;
}

.toglerLink {
	position: absolute;
	top: 27px;
	left: 0;
	width: 7rem;
	height: 2rem;
	display: none;
	z-index: 12;
	cursor: pointer;
}

.toglerLinkScrool {
	display: inline-block;
}

.aboutLinkShodow {
	position: absolute;
	width: 100%;
	height: 100%;

	top: 0;
	left: 0;

	box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
	z-index: 10;
}

.mdModal {
	position: absolute;
	top: 55px;
	left: 51px;
	height: auto;
	z-index: 2000;
	visibility: hidden;
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	backface-visibility: hidden;
	-webkit-transform: translateX(-50%) translateY(-50%);
	-moz-transform: translateX(-50%) translateY(-50%);
	-ms-transform: translateX(-50%) translateY(-50%);
	transform: translateX(-50%) translateY(-50%);

	// backdrop-filter: blur(7px);
	background: transparent;
	padding-top: 3.5rem;
}

.mdShow {
	visibility: visible;
}

.mdShow~.mdOverlay {
	opacity: 1;
	visibility: visible;
}

.mdContent {
	display: flex;
	flex-direction: column;
	width: max-content;

	padding: 0rem 1rem 0.5rem 1rem;
	backdrop-filter: blur(0px);
	background: rgb(255 255 255 / 100%);
	box-shadow: 0 10px 10px rgb(0 0 0 / 10%);

	&>a {
		margin-bottom: 0.5rem;
	}

	&>a:hover {
		color: #fcb53b;
	}
}

.mdEffect10.mdModal {
	-webkit-perspective: 1300px;
	-moz-perspective: 1300px;
	perspective: 1300px;
}

.mdEffect10 .mdContent {
	-webkit-transform-style: preserve-3d;
	-moz-transform-style: preserve-3d;
	transform-style: preserve-3d;
	-webkit-transform: rotateX(-60deg);
	-moz-transform: rotateX(-60deg);
	-ms-transform: rotateX(-60deg);
	transform: rotateX(-60deg);
	-webkit-transform-origin: 50% 0;
	-moz-transform-origin: 50% 0;
	transform-origin: 50% 0;
	opacity: 0;
	-webkit-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
}

.mdShow.mdEffect10 .mdContent {
	-webkit-transform: rotateX(0deg);
	-moz-transform: rotateX(0deg);
	-ms-transform: rotateX(0deg);
	transform: rotateX(0deg);
	opacity: 1;
}